<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 



			<div class="row">

				<div class="col-md-6">


			<div style="padding-top: 40px;">

				<div style="text-align:center;">


					<video width="200" height="200" autoplay loop muted>
				  <source src="/assets/images/Animation showing different users' Memojis surrounded by the icons of the apps the user personally uses most.mp4" type="video/mp4">
				Your browser does not support the video tag.
				</video>
				<br>
				<!--
					<div class="spinner-grow text-danger" style="width: 3rem; height: 3rem;" role="status">
				  <span class="visually-hidden">Loading...</span>
				</div>-->


					<h6>Please wait...</h6>
					<h6>Waiting for someone to join your chat</h6>
					<h5> {{countdown}} </h5>
					<button class="btn btn-1 mt-3" @click="leave()">Leave</button>
				
				</div>

				
			</div>
						

				</div>



				<div class="col-md-6">
					
				</div>

				
			</div>

	

		

			
		</div> 
	</div> 
	


</template>

<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import { io } from "socket.io-client"

	export default{
		name : 'home',
		components : { SideBar, TopBar },
		data(){
			return{
				socket : io(this.$store.state.socketio_server),
				topics : [],
				countdown : '03:00',
				x : ''
			}
		},

		methods : {

			leave(){
				this.socket.emit('leave waiting room', { 'room' : this.$store.state.current_topic_hash } )
				clearInterval(this.x)
				localStorage.setItem('was_topic_clicked','yes')
				this.$store.state.was_topic_clicked = 'yes'

				localStorage.setItem('am_in_the_waiting_room','no')
				this.$store.state.am_in_the_waiting_room = 'no'

				localStorage.setItem('distance2','')
				this.$store.state.distance2 = ''

				this.$router.push('/home')
			},

						timer(){
				//var distance = 180000
				var minutes = ''
				var seconds = ''
				this.x = setInterval(()=>{
	minutes = Math.floor(( parseInt(localStorage.getItem('distance2')) % (1000 * 60 * 60)) / (1000 * 60));
  seconds = Math.floor((parseInt(localStorage.getItem('distance2')) % (1000 * 60)) / 1000)
  if(minutes<10){
  	minutes = '0' + minutes.toString()
  }

  if(seconds<10){
  	seconds = '0'+seconds.toString()
  }

  this.countdown = minutes+':'+seconds

    let d = parseInt(localStorage.getItem('distance2'))
  d-=1000
   localStorage.setItem('distance2', d)


  if ( parseInt(localStorage.getItem('distance2')) < 0) {
  	localStorage.setItem('distance2',180000)
  	this.$store.state.distance2 = 180000

  	this.leave()
    clearInterval(this.x)
  }
				},1000)
			},

		},

		created(){

			//set the timer
			if(!this.$store.state.distance2 ){
				localStorage.setItem('distance2',180000)
				this.$store.state.distance2 = localStorage.getItem('distance2')
			}

			

			if(this.$store.state.am_in_the_waiting_room != 'yes'){
				this.$router.push('/home')
			}


			//broadcast to fetch topics
  				this.socket.emit('fetch chat topics', { 'room' : 'wating room', 'user_id' : this.$store.state.user_id })

  			//check if your topic has been clicked
  				this.socket.on('my clicked topic',(data)=>{
  					
  					if(data==this.$store.state.current_topic_hash){
  						//this topic has been clicked
  						//go to chatting page
  						//join chat room
  						clearInterval(this.x)
  						localStorage.setItem('was_topic_clicked','yes')
  						this.$store.state.was_topic_clicked = 'yes'

  						localStorage.setItem('topic_owner',this.$store.state.user_id)
  						this.$store.state.topic_owner = this.$store.state.user_id

  						localStorage.setItem('chatting_in_progress','yes')
							this.$store.state.chatting_in_progress = 'yes'

							localStorage.setItem('am_in_the_waiting_room','no')
							this.$store.state.am_in_the_waiting_room = 'no'

								//reset waiting time
								localStorage.setItem('distance2','')
								this.$store.state.distance2 = ''
								//reset chat time
								localStorage.setItem('distance','')
								this.$store.state.distance = ''

  						this.$router.push('/chat')
  					}
  				
  				})
  				//call the timer
  				this.timer()
		}

	}
</script>

