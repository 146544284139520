<template>


<section style="padding-top: 100px;">
	
	<div class="container">

		<div class="row">


			<div class="col-md-4">
			
			</div>
			<div class="col-md-4">
				
				<div style="border: solid 1px  #f0f0f0;" class="rounded p-3">

					<div style="padding-bottom: 30px; text-align: center;">
						<img src="/assets/images/logo.png" class="logo">
					</div>

					<p class="text-danger" v-if="message"> {{message}} </p>
					
					<form method="post" @submit.prevent="login()">
						
						<div>
							<input v-model="email" type="text" placeholder="Email address" class="form-control">
						</div>


						<div class="mt-2">
							<input v-model="password" type="password" placeholder="Password" class="form-control">
						</div>

						<div class="mt-2">
							<button class="btn btn-1 w-100">Login</button>
						</div>

						<div class="mt-3" style="text-align: center;">
							<router-link to="/forgot-password" class="btn p-0 ">Forgot password</router-link>
						</div>

					</form>

				</div>


				<div style="border: solid 1px  #f0f0f0;" class="rounded p-3 mt-3">
					Don't have an account? <router-link to="/register" class="btn p-0 text-success">Sign Up</router-link>
				</div>

			</div>
			<div class="col-md-4"></div>
			
		</div>
		
	</div>


</section>



<section style="padding-top:30px;">
	
	<div style="text-align:center;">
		<router-link to="/" class="btn color-3">Terms & Conditions</router-link>
		<router-link to="/" class="btn color-3">Data Privacy Policy</router-link>
	</div>

</section>



</template>


<script>
	import axios from 'axios'
	export default{
		name : 'login',
		data(){
			return{
				email : '',
				password : '',
				message : ''
			}
		},

		methods : {
			async login(){

				if(!this.email){
					this.message = 'Invalid email!'
					return
				}

				if(!this.password){
					this.message = 'Invalid password!'
					return
				}

				const res = await axios.post(this.$store.state.url+'login',{
					email : this.email,
					password : this.password
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res.length>0){

					res.forEach((data)=>{
						localStorage.setItem('email',data.email)
						localStorage.setItem('phone',data.phone)
						localStorage.setItem('user_id',data.userId)
						this.$store.state.user_id = data.userId
						localStorage.setItem('logged_in','yes')
						this.$store.state.logged_in = 'yes'

						localStorage.setItem('background',data.background)
						this.$store.state.background = data.background

						localStorage.setItem('created',data.created)
						this.$store.state.created = data.created

					})
					this.$router.push('/home')

				}else{
					this.message = 'Wrong email or password!'
				}
			}


		}
	}

</script>


