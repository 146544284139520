<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 

				<div>

	</div>

			<div class="row" style="padding-top:40px;">

				<div class="col-md-6">
							<div style="overflow: auto; white-space: nowrap; padding-bottom: 1px;">
			<router-link @mouseleave="title_modal=false" @mouseover="show_title(p.post_title)" :to=" { name : 'view-post', params : { post_id : p.post_id } } " v-for="p in posts" class="btn post-btn" :style=" { 'display' : 'inline-block', 'backgroundColor' : 'rgb('+p.r+','+p.g+','+p.b+')' } ">  {{ p.post_title[0] }}  </router-link>

			</div>




			<div style="padding-top:30px;">

				<div class="row mb-3">

					<div class="col-md-6 col-6">
						<h4>Welcome</h4>
					</div>

					<div class="col-md-6 col-6" style="text-align:right;">
						<router-link to="/join-chat" class="btn btn-outline-warning">Join Chat</router-link>
					</div>
					
				</div>


				
				
				<textarea @input="get_characters()" @keyup.enter="create_topic()" placeholder="What's on your mind?" class="form-control" rows="6" v-model="topic_name"></textarea>

		

				<div class="row">
					<div class="col-md-6 col-6">
						
						<button :disabled="disabled || this.$store.state.am_in_the_waiting_room=='yes' "  @click="create_topic()" class="btn btn-1 mt-2"> <i class="bi bi-plus"></i> Create</button>
						
					</div>

					<div class="col-md-6 col-6 p-2" style="text-align:right;">
						<button v-if="topic_name" @click="clear()" class="btn color-3"> <i class="bi bi-x"></i> Clear</button>
						<label style="border:solid 2px #f75318; padding: 6px; border-radius: 50px; color: #f75318; height: 40px; width: 40px; text-align: center; "> {{character_limit}} </label>
					</div>

				</div>

			</div>



			<div style="padding-top: 40px; height: 100vh; overflow: scroll;">

				<h4>Trending</h4>

			<div>
				
					<div v-for="t in topics">
					
					<button :disabled="this.$store.state.am_in_the_waiting_room=='yes'" @click="create_from_trending(t.topicName)" class="btn p-2 mt-3 trending-topic-btn"> <i class="bi bi-dot color-1"></i> {{t.topicName}} </button>
		
				</div>

			</div>

				
			</div>



				</div>



				<div class="col-md-6">


			<div style="padding-top: 40px; height: 100vh; overflow: scroll;">

				<h4>Save for later</h4>

<div v-if="message" class="alert alert-warning alert-dismissible fade show animate__animated animate__lightSpeedInRight" role="alert">
  {{message}}
  <button @click="message=''" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>


				<div>
					
					<div v-for="t in topics">
						<button @click="save_for_later(t.topicHash)" class="btn p-2 mt-3 border-bottom trending-topic-btn"> <i class="bi bi-check color-1"></i> {{t.topicName}}</button>
					</div>


				</div>

				
			</div>



			<div style="padding-top:70px; text-align: left;">
						<h6>Maifes Inc.All rights reserved</h6>
					</div>
					
				</div>

				
			</div>

	

		

			
		</div> 
	</div> 




	<div style="top:0; right: 0; position: absolute; z-index: 1200; width: 100%; margin-top: 200px; " v-if="title_modal">
		
		<div class="row">
			<div class="col-md-2"></div>
			<div class="col-md-4">
				<div style="background-color:#fff; text-align: center; " class="shadow-lg rounded p-3 animate__animated animate__lightSpeedInRight">
					<h6> {{post_title}} </h6>
				</div>
			</div>
			<div class="col-md-4"></div>

		</div>

	</div>
	


</template>

<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'
	import 'animate.css'

	export default{
		name : 'home',
		components : { SideBar, TopBar },
		data(){
			return{
				topics : [],
				message : '',
				topic_name : '',
				posts : [],
				title_modal : false,
				post_title : '',
				character_limit : 150,
				disabled : false,
				bad_words : ['fuck','matako', 'pussy', 'nigga' ],
				disabled2 : false
			
			}
		},

		methods : {

			 check_words(){
				this.bad_words.forEach((data)=>{

					if(this.topic_name.includes(data)){
						this.disabled = true

					}


				})


			},

			clear(){
				this.topic_name = ''
				this.character_limit = 150
			},

			get_characters(){

				
	
				let c = this.topic_name.length
				this.character_limit = 150 - c
				if(this.character_limit<=0){
					this.character_limit = 0
					this.disabled = true
					
				}

				if(this.character_limit>0){
					this.disabled = false
				}


			this.check_words()			

			},

			show_title(title){
				this.post_title = title
				this.title_modal = true
			},

			create_from_trending(topic_name){
				this.topic_name = topic_name
				this.create_topic()
			},

			create_topic(){
				if(!this.topic_name){
					return
				}

				localStorage.setItem('current_topic_name',this.topic_name)
				this.$store.state.current_topic_name = this.topic_name
				let hash = Date.now()
				localStorage.setItem('current_topic_hash',hash)
				this.$store.state.current_topic_hash = hash

				this.create_topic_now()

				localStorage.setItem('am_in_the_waiting_room','yes')
				this.$store.state.am_in_the_waiting_room = 'yes'

				localStorage.setItem('was_topic_clicked','no')
			this.$store.state.was_topic_clicked = 'no'

				this.$router.push('/waiting-room')

			},


			async create_topic_now(){
				const res = await axios.post(this.$store.state.url+"create-topic",{
					user_id : this.$store.state.user_id,
					topic_hash : this.$store.state.current_topic_hash,
					topic_name : this.$store.state.current_topic_name,
					klub : 0
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				
				if(res=='success'){
					this.message = 'Topic added to your library!'
				}
				 
			},




			async save_for_later(topic_hash){
				this.message = ''
				const res = await axios.post(this.$store.state.url+"save-for-later",{
					user_id : this.$store.state.user_id,
					topic_hash : topic_hash
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				
				if(res=='success'){
					this.message = 'Topic added to your library!'
				}
				 
			},


			async get_topics(){
				const res = await axios.get(this.$store.state.url+"trending-topics").then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.topics = res 
			},


			async get_posts(){
				const res = await axios.get(this.$store.state.url+"get-posts/"+this.$store.state.user_id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.posts = res 
			}


		},


		created(){
			localStorage.setItem('distance2',300000)
			this.$store.state.distance2 = 300000
			this.get_topics()
			this.get_posts()
			localStorage.setItem('chatting_in_progress','no')
			this.$store.state.chatting_in_progress = 'no'
		}

	}
</script>

