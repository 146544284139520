<template>
	<div class="navcontainer"> 
			<nav class="nav"> 
				<div class="nav-upper-options"> 


					<div class="nav-option" style="margin-top: 70px;"> 
					<router-link class="btn  option1" to="/home"> <b> <i class="bi bi-house"></i> Home</b></router-link> 
					</div> 


	<!--


					<div class="nav-option">
	<div class="dropdown">
  <button class="btn option1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
     <i class="bi bi-layers"></i> Products
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><router-link to="/new-product" class="dropdown-item">Add new</router-link></li>
    <li><router-link to="/view-products" class="dropdown-item">View  products</router-link></li>

  </ul>
</div>
						
					</div>

				-->

				<div class="nav-option"> 
					<router-link to="/join-chat" class="btn option1"><i class="bi bi-chat-right-dots"></i> Join Chat</router-link> 
					</div>


					<div class="nav-option"> 
					<router-link to="/klub"  class="btn option1"><i class="bi bi-share"></i> My Klub</router-link> 
					</div>


					<div class="nav-option"> 
					<router-link to="/create-post" class="btn option1"><i class="bi bi-plus-square"></i> Create Post</router-link> 
					</div>


					<div class="nav-option"> 
					<router-link to="/notifications" class="btn option1"><i :class=" { 'bi bi-heart color-1' : this.$store.state.post_liked=='true' || this.$store.state.new_post_comment=='true' || this.$store.state.new_comment_reply=='true' , 'bi bi-heart' : this.$store.state.post_liked!='true' || this.$store.state.new_post_comment!='true' || this.$store.state.new_comment_reply!='true' } "></i> Notifications</router-link> 
					</div>


					<div class="nav-option"> 
					<router-link to="/trending"  class="btn option1"><i class="bi bi-bar-chart"></i> Trending</router-link> 
					</div>


					<div class="nav-option"> 
					<router-link to="/saved"  class="btn option1"><i class="bi bi-save2"></i> Saved</router-link> 
					</div>


					<div class="nav-option"> 
					<router-link to="/history" class="btn option1"><i class="bi bi-clock-history"></i> History</router-link> 
					</div>


					<div class="nav-option"> 
					<router-link to="/profile" class="btn option1"><i class="bi bi-gear"></i> Profile</router-link> 
					</div>



			


						<div class="nav-option"> 
					<button @click="logout()" class="btn option1"><i class="bi bi-power"></i> Log Out</button> 
					</div> 


					
				</div> 
			</nav> 
		</div>
</template>


<script>
	import { io } from "socket.io-client"

	export default{

		name : 'sidebar',

		data(){
			return{
				socket : io(this.$store.state.socketio_server),
				post_liked : false

			}
		},

		methods : {
			logout(){
				localStorage.clear()
				this.$router.push('/')
			}
		},

		created(){
			if(this.$store.state.logged_in!='yes'){
				this.$router.push('/')
			}

			//check if a post was liked 

			this.socket.on('post for liked',(data)=>{
				
				if(data.post_owner==this.$store.state.user_id){
					this.post_liked = 'true'
					this.$store.state.post_liked = 'true'
					localStorage.setItem('post_liked','true')
					
				}
			})

			//check if a post was commented on
			this.socket.on('new post comment',(data)=>{


				if(data.post_owner==this.$store.state.user_id){
					this.$store.state.new_post_comment = 'true'
					localStorage.setItem('new_post_comment','true')
					
					
				}

			})



			//check if a comment was replied on 
			this.socket.on('reply received',(data)=>{

				if(data.comment_owner==this.$store.state.user_id){
					this.$store.state.new_comment_reply = 'true'
					localStorage.setItem('new_comment_reply','true')

					
				}

			})




		}

	}

</script>