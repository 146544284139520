<template>


<section style="padding-top: 100px;">
	
	<div class="container">

		<div class="row">


			<div class="col-md-4">
			
			</div>
			<div class="col-md-4">
				
				<div style="border: solid 1px  #f0f0f0;" class="rounded p-3">

					<div style="padding-bottom: 30px; text-align: center;">
						<img src="/assets/images/logo.png" class="logo">
					</div>

					<p v-if="message" :class="text_class"> {{message}} </p>
					
					<form @submit.prevent="register()" method="post">
						
						<div>
							<input type="text" placeholder="Email address" class="form-control" v-model="email">
						</div>

						<div class="mt-2">
							<input type="text" placeholder="Phone number" class="form-control" v-model="phone">
						</div>


						<div class="mt-2">
							<input type="password" placeholder="Password" class="form-control" v-model="password">
						</div>

						<div class="mt-2">
							<button class="btn btn-1 w-100">Sign Up</button>
						</div>

						

					</form>

				</div>


				<div style="border: solid 1px  #f0f0f0;" class="rounded p-3 mt-3">
					Already have an account? <router-link to="/" class="btn p-0 text-success">Login</router-link>
				</div>

			</div>
			<div class="col-md-4"></div>
			
		</div>
		
	</div>


</section>



<section style="padding-top:30px;">
	
	<div style="text-align:center;">
		<router-link to="/" class="btn color-3">Terms & Conditions</router-link>
		<router-link to="/" class="btn color-3">Data Privacy Policy</router-link>
	</div>

</section>



</template>

<script>

	import axios from 'axios'
	
	export default{
		name : 'register',
		data(){
			return{
				email : '',
				phone : '',
				password : '',
				message : ''

			}
		},

		methods : {

				async register(){

				if(!this.email){
					this.text_class = 'text-danger'
					this.message = 'Email is required!'
					return
				}

				if(!this.phone){
					this.text_class = 'text-danger'
					this.message = 'Phone is required!'
					return
				}

				if(!this.password){
					this.text_class = 'text-danger'
					this.message = 'Password is required!'
					return
				}

				const res = await axios.post(this.$store.state.url+'register',{
					email : this.email,
					password : this.password,
					phone : this.phone
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res=='success'){
					this.text_class = 'text-success'
					this.message = 'Account created successfully'
					setTimeout(()=>{
						this.$router.push('/')
					},2000)
				}else{
					this.text_class = 'text-danger'
					this.message = 'An account with that email already exists!'
				}

				
			}
		}

	}

</script>


