<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 



			<div class="row" style="padding-top:40px;">

				<div class="col-md-6">


			<div style="padding-top: 40px;">

				<router-link  class="btn p-0 color-1" to="/home"> <i class="bi bi-chevron-left"></i> Back </router-link>

				<h4>Trending</h4>

			
				<div>
					
					<div v-for="t in topics">

					<button :disabled="this.$store.state.am_in_the_waiting_room=='yes' || this.$store.state.am_in_klub_waiting_room=='yes'  " @click="create_from_trending(t.topicName)" class="btn p-2 mt-3 border-bottom trending-topic-btn "> <i class="bi bi-check color-1"></i> {{ t.topicName }} </button>
	
				</div>

				</div>

				
			</div>
						

				</div>



				<div class="col-md-6">
					
				</div>

				
			</div>

	

		

			
		</div> 
	</div> 
	


</template>

<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'

	export default{
		name : 'home',
		components : { SideBar, TopBar },
		data(){
			return{
				topics : [],
				topic_name : ''
			}
		},

		methods : {


			create_from_trending(topic_name){
				this.topic_name = topic_name
				this.create_topic()
			},

			create_topic(){
				if(!this.topic_name){
					return
				}

				localStorage.setItem('current_topic_name',this.topic_name)
				this.$store.state.current_topic_name = this.topic_name
				let hash = Date.now()
				localStorage.setItem('current_topic_hash',hash)
				this.$store.state.current_topic_hash = hash

				this.create_topic_now()


				localStorage.setItem('am_in_the_waiting_room','yes')
				this.$store.state.am_in_the_waiting_room = 'yes'

				this.$router.push('/waiting-room')

			},


			async create_topic_now(){
				const res = await axios.post(this.$store.state.url+"create-topic",{
					user_id : this.$store.state.user_id,
					topic_hash : this.$store.state.current_topic_hash,
					topic_name : this.$store.state.current_topic_name,
					klub : 0
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				
				if(res=='success'){
					this.message = 'Topic added to your library!'
				}
				 
			},


			async get_topics(){
				const res = await axios.get(this.$store.state.url+"trending-topics").then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.topics = res 
			}
		},

		created(){
			this.get_topics()


			localStorage.setItem('distance2',1800000)
			this.$store.state.distance2 = 1800000


			localStorage.setItem('chatting_in_progress','no')
			this.$store.state.chatting_in_progress = 'no'
		}

	}
</script>

